/* Style the scrollbar */
::-webkit-scrollbar {
    width: 6px;
    border-radius: 3px;
}

/* Style the thumb */
::-webkit-scrollbar-thumb {
    background-color: rgba(90, 90, 90, 0.3);
    border-radius: 3px
}

.MuiTypography-root {
    font-family: 'NouvelR';
}

.dot-flashing {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #9880ff;
    color: #9880ff;
    animation: dot-flashing 1s infinite linear alternate;
    animation-delay: 0.5s;
  }
  .dot-flashing::before, .dot-flashing::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
  }
  .dot-flashing::before {
    left: -15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #000;
    color: #000;
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 0s;
  }
  .dot-flashing::after {
    left: 15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #000;
    color: #000;
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 1s;
  }
  
  @keyframes dot-flashing {
    0% {
      background-color: #000;
    }
    50%, 100% {
      background-color: rgba(146, 146, 146, 0.4);
    }
  }